import React from "react";
import { Link } from 'react-router-dom'
import styles from "../css/HomePage.module.css"

const AboutUsPage = () => {

    return (
        <>
            <div className="container pt-5">
      <h2>Общественное объединение «Кыргызское общество слепых и глухих»</h2>
      <p className="mt-3">
        <span style={{paddingLeft:"40px"}}>Общественное</span>  объединение «Кыргызское общество слепых и глухих» (КОС и
        КОГ) – добровольное национальное республиканское общественное
        неправительственное объединение (организация) лиц с ограниченными
        возможностями здоровья (ЛОВЗ) по зрению и слуху, действует на основе
        самоуправления в соответствии с Конституцией, законодательством
        Кыргызской Республики и Уставом КОС и КОГ.
      </p>
      <p>
      <span style={{paddingLeft:"40px"}}>КОС и КОГ </span> объединяет три межобластные организации: Чуй-Таласскую,
        Иссык-Куль-Нарынскую и Южную региональную. Во всех районах и городах
        Республики ЛОВЗ по зрению и слуху объединены в 60 производственных,
        производственно-территориальных и территориальных первичных
        организациях.
      </p>
      <p>
        В составе Объединения действуют 11 предприятий, на которых трудятся ЛОВЗ,
        имеется пансионат «Орбита» на берегу озера Иссык-Куль, медицинский центр
        «Медикос», функционирует Республиканская специализированная библиотека
        для слепых и глухих.
      </p>
      <p>
        На предприятиях ЛОВЗ по зрению и слуху выпускают около 147 видов
        изделий, в том числе:
      </p>
      <ul>
        <li>матрацы;</li>
        <li>одеяла;</li>
        <li>подушки с разными наполнителями;</li>
        <li>комплекты постельного белья;</li>
        <li>специальная одежда;</li>
        <li>столы;</li>
        <li>стулья;</li>
        <li>двери;</li>
        <li>окна;</li>
        <li>парты;</li>
        <li>изделия из металла;</li>
        <li>изделия из ПВХ и т. д.</li>
      </ul>
      <p>
        Также членами нашего Объединения выращивается и перерабатывается
        сельскохозяйственная продукция.
      </p>
      <p>
        По состоянию на 1-е полугодие 2022 года членами Кыргызского общества
        слепых и глухих являются 7983 человека.
      </p>
      <p>
        Дети – ЛОВЗ по зрению и слуху посещают детский сад для детей с дефектами
        зрения в г.Бишкек, дети-ЛОВЗ по слуху посещают детсад в г. Бишкек и в
        селе.
      </p>
    </div>
        </>
    )

}
export default AboutUsPage;